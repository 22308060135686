













































































































































































.highlight {
  &.elevation-8  {
    box-shadow: 0 5px 5px -3px #ec7404, 0 8px 10px 1px #ec740480, 0 3px 14px 2px #ec740480 !important
  }
  &.elevation-10  {
    box-shadow: 0 6px 6px -3px #ec7404, 0 10px 14px 1px #ec740480, 0 4px 18px 3px #ec740480 !important;
  }
}
