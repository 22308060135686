














#cookie-consent {
    z-index: 9001;
}
#cookie-consent.cookie-footer {
  .cookie-wrapper {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
}
