































.hide-fileinput
  position absolute
  left -99999px
.file-img-container
  overflow-x auto

