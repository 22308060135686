










.not-activator
  width 100%
  height 0
