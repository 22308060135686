.radio-group__wrapper {
  margin-bottom: -8px;
}
.radio-group__wrapper >>> .v-input__slot {
  margin-bottom: 0;
}
.radio-group__wrapper >>> .v-input--radio-group__input > .v-label {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  padding-bottom: 0;
}
/*# sourceMappingURL=app/packs/backend/components/radio-group.css.map */