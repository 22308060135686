




























.saalplan
  transition-duration: 0.3s;
  transition-property: font-size;
  overflow auto
  &_scroller_bg
    background-color #eee
    background-image linear-gradient(to right, rgba(0,0,0,0.02) 50%, transparent 50%, transparent), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, transparent 50%, transparent)
    background-size 2em 2em
    background-repeat repeat
  &_scroller
    z-index 0
    position relative
  &_text
    position absolute
    transform-origin top left
    white-space nowrap
    line-height 1
  &_platz
    z-index 3
    position absolute
    width 0.9em
    height 0.9em
    margin 0.05em
    border-radius 50%
    border 1px solid black
    &_text
      height 100%
      display flex
      justify-content center
      align-items center
      font-size 0.3em

    &::before
      position absolute
      top 0
      left 0
      display block
      width 100%
      height 100%
      border-radius 50%

  .incomplete::before
    content ""
    box-shadow 0 0 4px 0.075em #c02

  .collision::before
    content ""
    box-shadow 0 0 4px 0.075em purple

  .remembered::before
    content ""
    box-shadow 0 0 4px 0.075em darkorange

  .reserved:not(.reserved-me)
    background-repeat: repeat;
    background-position: center;
    background-size: 11px 11px;
    &::before
      content "×"
      line-height 0.85
      opacity 0.4
      overflow hidden
      text-align center

    .saalplan_platz_text
      display: none

  .selecting &_platz:hover:not(.reserved):not(.selected)
    box-shadow 0 0 5px 0.075em orange
    z-index 4

  .selected, .reserved-me
    box-shadow 0 0 0px 0.075em black
    z-index 4

  .reserved-me:after
    content ""
    display block
    top -3px
    left -3px
    position relative
    width 1em
    height 1em
    border-radius 100%
    border 2px solid black
    background-repeat repeat
    background-image linear-gradient(45deg, #ffffff44 16.67%, #ffffff00 16.67%, #ffffff00 50%, #ffffff44 50%, #ffffff44 66.67%, #ffffff00 66.67%, #ffffff00 100%)
    background-size 21.21px 21.21px

  .selected.reserved:not(.reserved-me):after
    content ""
    display block
    top -3px
    left -3px
    position relative
    width 1em
    height 1em
    border-radius 100%
    border 2px solid black
    background-repeat repeat
    background-image linear-gradient(45deg, #f00 16.67%, #ffffff00 16.67%, #ffffff00 50%, #f00 50%, #f00 66.67%, #ffffff00 66.67%, #ffffff00 100%)
    background-size 21.21px 21.21px


  .selected.reserved
    box-shadow 0 0 0px 0.075em black
    z-index 4

  .verbindung
    z-index 1
    position absolute
    background-color lightgrey
    transform-origin left


