

















































@use "sass:math";

.side-logo {
  max-width: 100%;
  width: 130px;
  @media (min-width: 1264px) {
    width: 250px;
  }
}
.main-grid {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1264px) {
    flex-wrap: nowrap;
  }
  &--logo {
    order: 10;
    @media (min-width: 1264px) {
      order: 0;
    }
  }
  &--spacer {
    display: flex;
    @media (min-width: 1264px) {
      padding: calc(10% - 16px) 12px;
      margin-right: auto;
      margin-left: auto;
      max-width: 250px;
      flex-direction: column;
      height: 100%;
    }
  }
  $center-width-lg: 900px;
  $center-width-xl: 1185px;
  &--left {
    flex-grow: 1;
    width: 100%;
  }
  &--right {
    @media (min-width: 1264px) {
      order: 3;
    }
  }
  &--left, &--right {
    @media (min-width: 1264px) {
      height: round(math.div($center-width-lg, 3) * 1);
      min-width: 0;
      width: 50%;
    }
    @media (min-width: 1904px) {
      height: round(math.div($center-width-xl, 3) * 1);
    }
  }
  &--center {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media (min-width: 960px) {
      width: $center-width-lg;
    }
    @media (min-width: 1264px) {
      ::v-deep .v-main__wrap::before {
        content: "";
        display: block;
        width: $center-width-lg;
        @media (min-width: 1904px) {
          width: $center-width-xl;
        }
      }
    }
    @media (min-width: 1904px) {
      width: $center-width-xl;
    }
  }
}
.main-container {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: none;
}
.menu-footer::v-deep p:last-child {
  margin-bottom: 0;
}
