








































































































































































































.blocky-tabs .v-tab {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #f5f5f6!important;
  color: rgba(0, 0, 0, 0.87)!important;
  margin: 3px;
  &:before {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.v-tab--active:before, &:hover:before {
    opacity: 0.24;
  }
}
.wide-steps ::v-deep .v-stepper__step {
  flex-basis: 200px;
}
