






.radio-group__wrapper
  margin-bottom -8px
  >>>
    .v-input__slot
      margin-bottom 0
    .v-input--radio-group__input > .v-label
      font-size 12px
      font-weight 400
      width 100%
      padding-bottom 0

