.saalplan {
  transition-duration: 0.3s;
  transition-property: font-size;
  overflow: auto;
}
.saalplan_scroller_bg {
  background-color: #eee;
  background-image: linear-gradient(to right, rgba(0,0,0,0.02) 50%, transparent 50%, transparent), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, transparent 50%, transparent);
  background-size: 2em 2em;
  background-repeat: repeat;
}
.saalplan_scroller {
  z-index: 0;
  position: relative;
}
.saalplan_text {
  position: absolute;
  transform-origin: top left;
  white-space: nowrap;
  line-height: 1;
}
.saalplan_platz {
  z-index: 3;
  position: absolute;
  width: 0.9em;
  height: 0.9em;
  margin: 0.05em;
  border-radius: 50%;
  border: 1px solid #000;
}
.saalplan_platz_text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.3em;
}
.saalplan_platz::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.saalplan .incomplete::before {
  content: "";
  box-shadow: 0 0 4px 0.075em #c02;
}
.saalplan .collision::before {
  content: "";
  box-shadow: 0 0 4px 0.075em #800080;
}
.saalplan .remembered::before {
  content: "";
  box-shadow: 0 0 4px 0.075em #ff8c00;
}
.saalplan .reserved:not(.reserved-me) {
  background-repeat: repeat;
  background-position: center;
  background-size: 11px 11px;
}
.saalplan .reserved:not(.reserved-me)::before {
  content: "×";
  line-height: 0.85;
  opacity: 0.4;
  overflow: hidden;
  text-align: center;
}
.saalplan .reserved:not(.reserved-me) .saalplan_platz_text {
  display: none;
}
.selecting .saalplan_platz:hover:not(.reserved):not(.selected) {
  box-shadow: 0 0 5px 0.075em #ffa500;
  z-index: 4;
}
.saalplan .selected,
.saalplan .reserved-me {
  box-shadow: 0 0 0px 0.075em #000;
  z-index: 4;
}
.saalplan .reserved-me:after {
  content: "";
  display: block;
  top: -3px;
  left: -3px;
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  border: 2px solid #000;
  background-repeat: repeat;
  background-image: linear-gradient(45deg, rgba(255,255,255,0.267) 16.67%, rgba(255,255,255,0) 16.67%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.267) 50%, rgba(255,255,255,0.267) 66.67%, rgba(255,255,255,0) 66.67%, rgba(255,255,255,0) 100%);
  background-size: 21.21px 21.21px;
}
.saalplan .selected.reserved:not(.reserved-me):after {
  content: "";
  display: block;
  top: -3px;
  left: -3px;
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  border: 2px solid #000;
  background-repeat: repeat;
  background-image: linear-gradient(45deg, #f00 16.67%, rgba(255,255,255,0) 16.67%, rgba(255,255,255,0) 50%, #f00 50%, #f00 66.67%, rgba(255,255,255,0) 66.67%, rgba(255,255,255,0) 100%);
  background-size: 21.21px 21.21px;
}
.saalplan .selected.reserved {
  box-shadow: 0 0 0px 0.075em #000;
  z-index: 4;
}
.saalplan .verbindung {
  z-index: 1;
  position: absolute;
  background-color: #d3d3d3;
  transform-origin: left;
}
/*# sourceMappingURL=app/packs/backend/components/saalplan-show.css.map */