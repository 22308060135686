
@import "vuetify2/dist/vuetify.min.css";
@import '@mdi/font/css/materialdesignicons.css';

html {
  overflow-wrap: anywhere;
}
.v-card__title {
  word-break: break-word;
}

h1, h2, h3, h4, h5, h6 {
  color: #ec7404;
}
// .primary-headlines {
// }
.justify-space-evenly {
  justify-content: space-evenly !important;
}

.text-wrap-lines {
  white-space: pre-line;
}

.btn-multiline {
  ::v-deep .v-btn__content {
    max-width: 100%;
    white-space: normal;
  }
}

.no-p-margin-last > p:last-child {
  margin-bottom: 0;
}

.accent--links a {
  color: #0053a6 !important;
  caret-color: #0053a6 !important;
}

@for $i from 1 through 9 {
  .primary.transparent-#{$i} {
    background-color: rgba(236, 116, 4, $i * 10% ) !important;
    border-color: rgba(236, 116, 4, $i * 10%) !important;
  }
  .white.transparent-#{$i} {
    background-color: rgba(255, 255, 255, $i * 10%) !important;
    border-color: rgba(255, 255, 255, $i * 10%) !important;
  }
}

// .primary.transparent-2 {
//   background-color: rgba(236, 116, 4, 0.2) !important;
//   border-color: rgba(236, 116, 4, 0.2) !important;
// }
// .primary.transparent-3 {
//   background-color: rgba(236, 116, 4, 0.3) !important;
//   border-color: rgba(236, 116, 4, 0.3) !important;
// }
// .primary.transparent-4 {
//   background-color: rgba(236, 116, 4, 0.4) !important;
//   border-color: rgba(236, 116, 4, 0.4) !important;
// }
// .primary.transparent-5 {
//   background-color: rgba(236, 116, 4, 0.5) !important;
//   border-color: rgba(236, 116, 4, 0.5) !important;
// }
// .primary.transparent-6 {
//   background-color: rgba(236, 116, 4, 0.6) !important;
//   border-color: rgba(236, 116, 4, 0.6) !important;
// }
// .primary.transparent-7 {
//   background-color: rgba(236, 116, 4, 0.7) !important;
//   border-color: rgba(236, 116, 4, 0.7) !important;
// }
// .primary.transparent-8 {
//   background-color: rgba(236, 116, 4, 0.8) !important;
//   border-color: rgba(236, 116, 4, 0.8) !important;
// }
// .primary.transparent-9 {
//   background-color: rgba(236, 116, 4, 0.9) !important;
//   border-color: rgba(236, 116, 4, 0.9) !important;
// }


// .white.transparent-1 {
//   background-color: rgba(236, 116, 4, 0.1) !important;
//   border-color: rgba(236, 116, 4, 0.1) !important;
// }
// .white.transparent-2 {
//   background-color: rgba(236, 116, 4, 0.2) !important;
//   border-color: rgba(236, 116, 4, 0.2) !important;
// }
// .white.transparent-3 {
//   background-color: rgba(236, 116, 4, 0.3) !important;
//   border-color: rgba(236, 116, 4, 0.3) !important;
// }
// .white.transparent-4 {
//   background-color: rgba(236, 116, 4, 0.4) !important;
//   border-color: rgba(236, 116, 4, 0.4) !important;
// }
// .white.transparent-5 {
//   background-color: rgba(236, 116, 4, 0.5) !important;
//   border-color: rgba(236, 116, 4, 0.5) !important;
// }
// .white.transparent-6 {
//   background-color: rgba(236, 116, 4, 0.6) !important;
//   border-color: rgba(236, 116, 4, 0.6) !important;
// }
// .white.transparent-7 {
//   background-color: rgba(236, 116, 4, 0.7) !important;
//   border-color: rgba(236, 116, 4, 0.7) !important;
// }
// .white.transparent-8 {
//   background-color: rgba(236, 116, 4, 0.8) !important;
//   border-color: rgba(236, 116, 4, 0.8) !important;
// }
// .white.transparent-9 {
//   background-color: rgba(236, 116, 4, 0.9) !important;
//   border-color: rgba(236, 116, 4, 0.9) !important;
// }

/* FIXME this should be autogenerated */
a { color: #ec7404; }
.primary {
  background-color: #ec7404 !important;
  border-color: #ec7404 !important;
}
.primary--text {
  color: #ec7404 !important;
  caret-color: #ec7404 !important;
}
.primary.lighten-5 {
  background-color: #ffff99 !important;
  border-color: #ffff99 !important;
}
.primary--text.text--lighten-5 {
  color: #ffff99 !important;
  caret-color: #ffff99 !important;
}
.primary.lighten-4 {
  background-color: #ffe27d !important;
  border-color: #ffe27d !important;
}
.primary--text.text--lighten-4 {
  color: #ffe27d !important;
  caret-color: #ffe27d !important;
}
.primary.lighten-3 {
  background-color: #ffc662 !important;
  border-color: #ffc662 !important;
}
.primary--text.text--lighten-3 {
  color: #ffc662 !important;
  caret-color: #ffc662 !important;
}
.primary.lighten-2 {
  background-color: #ffaa47 !important;
  border-color: #ffaa47 !important;
}
.primary--text.text--lighten-2 {
  color: #ffaa47 !important;
  caret-color: #ffaa47 !important;
}
.primary.lighten-1 {
  background-color: #ff8f2b !important;
  border-color: #ff8f2b !important;
}
.primary--text.text--lighten-1 {
  color: #ff8f2b !important;
  caret-color: #ff8f2b !important;
}
.primary.darken-1 {
  background-color: #cc5a00 !important;
  border-color: #cc5a00 !important;
}
.primary--text.text--darken-1 {
  color: #cc5a00 !important;
  caret-color: #cc5a00 !important;
}
.primary.darken-2 {
  background-color: #ad4000 !important;
  border-color: #ad4000 !important;
}
.primary--text.text--darken-2 {
  color: #ad4000 !important;
  caret-color: #ad4000 !important;
}
.primary.darken-3 {
  background-color: #8e2600 !important;
  border-color: #8e2600 !important;
}
.primary--text.text--darken-3 {
  color: #8e2600 !important;
  caret-color: #8e2600 !important;
}
.primary.darken-4 {
  background-color: #720400 !important;
  border-color: #720400 !important;
}
.primary--text.text--darken-4 {
  color: #720400 !important;
  caret-color: #720400 !important;
}
.secondary {
  background-color: #5b9bd5 !important;
  border-color: #5b9bd5 !important;
}
.secondary--text {
  color: #5b9bd5 !important;
  caret-color: #5b9bd5 !important;
}
.secondary.lighten-5 {
  background-color: #edffff !important;
  border-color: #edffff !important;
}
.secondary--text.text--lighten-5 {
  color: #edffff !important;
  caret-color: #edffff !important;
}
.secondary.lighten-4 {
  background-color: #d0ffff !important;
  border-color: #d0ffff !important;
}
.secondary--text.text--lighten-4 {
  color: #d0ffff !important;
  caret-color: #d0ffff !important;
}
.secondary.lighten-3 {
  background-color: #b2edff !important;
  border-color: #b2edff !important;
}
.secondary--text.text--lighten-3 {
  color: #b2edff !important;
  caret-color: #b2edff !important;
}
.secondary.lighten-2 {
  background-color: #95d1ff !important;
  border-color: #95d1ff !important;
}
.secondary--text.text--lighten-2 {
  color: #95d1ff !important;
  caret-color: #95d1ff !important;
}
.secondary.lighten-1 {
  background-color: #78b6f2 !important;
  border-color: #78b6f2 !important;
}
.secondary--text.text--lighten-1 {
  color: #78b6f2 !important;
  caret-color: #78b6f2 !important;
}
.secondary.darken-1 {
  background-color: #3c81b9 !important;
  border-color: #3c81b9 !important;
}
.secondary--text.text--darken-1 {
  color: #3c81b9 !important;
  caret-color: #3c81b9 !important;
}
.secondary.darken-2 {
  background-color: #13689e !important;
  border-color: #13689e !important;
}
.secondary--text.text--darken-2 {
  color: #13689e !important;
  caret-color: #13689e !important;
}
.secondary.darken-3 {
  background-color: #005084 !important;
  border-color: #005084 !important;
}
.secondary--text.text--darken-3 {
  color: #005084 !important;
  caret-color: #005084 !important;
}
.secondary.darken-4 {
  background-color: #003a6a !important;
  border-color: #003a6a !important;
}
.secondary--text.text--darken-4 {
  color: #003a6a !important;
  caret-color: #003a6a !important;
}
.accent {
  background-color: #0053a6 !important;
  border-color: #0053a6 !important;
}
.accent--text {
  color: #0053a6 !important;
  caret-color: #0053a6 !important;
}
.accent.lighten-5 {
  background-color: #abdaff !important;
  border-color: #abdaff !important;
}
.accent--text.text--lighten-5 {
  color: #abdaff !important;
  caret-color: #abdaff !important;
}
.accent.lighten-4 {
  background-color: #8ebfff !important;
  border-color: #8ebfff !important;
}
.accent--text.text--lighten-4 {
  color: #8ebfff !important;
  caret-color: #8ebfff !important;
}
.accent.lighten-3 {
  background-color: #72a4e9 !important;
  border-color: #72a4e9 !important;
}
.accent--text.text--lighten-3 {
  color: #72a4e9 !important;
  caret-color: #72a4e9 !important;
}
.accent.lighten-2 {
  background-color: #5489cd !important;
  border-color: #5489cd !important;
}
.accent--text.text--lighten-2 {
  color: #5489cd !important;
  caret-color: #5489cd !important;
}
.accent.lighten-1 {
  background-color: #3570b1 !important;
  border-color: #3570b1 !important;
}
.accent--text.text--lighten-1 {
  color: #3570b1 !important;
  caret-color: #3570b1 !important;
}
.accent.darken-1 {
  background-color: #00417c !important;
  border-color: #00417c !important;
}
.accent--text.text--darken-1 {
  color: #00417c !important;
  caret-color: #00417c !important;
}
.accent.darken-2 {
  background-color: #002b63 !important;
  border-color: #002b63 !important;
}
.accent--text.text--darken-2 {
  color: #002b63 !important;
  caret-color: #002b63 !important;
}
.accent.darken-3 {
  background-color: #00184a !important;
  border-color: #00184a !important;
}
.accent--text.text--darken-3 {
  color: #00184a !important;
  caret-color: #00184a !important;
}
.accent.darken-4 {
  background-color: #000034 !important;
  border-color: #000034 !important;
}
.accent--text.text--darken-4 {
  color: #000034 !important;
  caret-color: #000034 !important;
}
.error {
  background-color: #b71c1c !important;
  border-color: #b71c1c !important;
}
.error--text {
  color: #b71c1c !important;
  caret-color: #b71c1c !important;
}
.error.lighten-5 {
  background-color: #ffaf95 !important;
  border-color: #ffaf95 !important;
}
.error--text.text--lighten-5 {
  color: #ffaf95 !important;
  caret-color: #ffaf95 !important;
}
.error.lighten-4 {
  background-color: #ff937b !important;
  border-color: #ff937b !important;
}
.error--text.text--lighten-4 {
  color: #ff937b !important;
  caret-color: #ff937b !important;
}
.error.lighten-3 {
  background-color: #ff7762 !important;
  border-color: #ff7762 !important;
}
.error--text.text--lighten-3 {
  color: #ff7762 !important;
  caret-color: #ff7762 !important;
}
.error.lighten-2 {
  background-color: #f75b4a !important;
  border-color: #f75b4a !important;
}
.error--text.text--lighten-2 {
  color: #f75b4a !important;
  caret-color: #f75b4a !important;
}
.error.lighten-1 {
  background-color: #d73e32 !important;
  border-color: #d73e32 !important;
}
.error--text.text--lighten-1 {
  color: #d73e32 !important;
  caret-color: #d73e32 !important;
}
.error.darken-1 {
  background-color: #980005 !important;
  border-color: #980005 !important;
}
.error--text.text--darken-1 {
  color: #980005 !important;
  caret-color: #980005 !important;
}
.error.darken-2 {
  background-color: #7a0000 !important;
  border-color: #7a0000 !important;
}
.error--text.text--darken-2 {
  color: #7a0000 !important;
  caret-color: #7a0000 !important;
}
.error.darken-3 {
  background-color: #5e0000 !important;
  border-color: #5e0000 !important;
}
.error--text.text--darken-3 {
  color: #5e0000 !important;
  caret-color: #5e0000 !important;
}
.error.darken-4 {
  background-color: #480000 !important;
  border-color: #480000 !important;
}
.error--text.text--darken-4 {
  color: #480000 !important;
  caret-color: #480000 !important;
}
.info {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}
.info--text {
  color: #0d47a1 !important;
  caret-color: #0d47a1 !important;
}
.info.lighten-5 {
  background-color: #b3c6ff !important;
  border-color: #b3c6ff !important;
}
.info--text.text--lighten-5 {
  color: #b3c6ff !important;
  caret-color: #b3c6ff !important;
}
.info.lighten-4 {
  background-color: #95abff !important;
  border-color: #95abff !important;
}
.info--text.text--lighten-4 {
  color: #95abff !important;
  caret-color: #95abff !important;
}
.info.lighten-3 {
  background-color: #7890f5 !important;
  border-color: #7890f5 !important;
}
.info--text.text--lighten-3 {
  color: #7890f5 !important;
  caret-color: #7890f5 !important;
}
.info.lighten-2 {
  background-color: #5a77d9 !important;
  border-color: #5a77d9 !important;
}
.info--text.text--lighten-2 {
  color: #5a77d9 !important;
  caret-color: #5a77d9 !important;
}
.info.lighten-1 {
  background-color: #3a5ebc !important;
  border-color: #3a5ebc !important;
}
.info--text.text--lighten-1 {
  color: #3a5ebc !important;
  caret-color: #3a5ebc !important;
}
.info.darken-1 {
  background-color: #003186 !important;
  border-color: #003186 !important;
}
.info--text.text--darken-1 {
  color: #003186 !important;
  caret-color: #003186 !important;
}
.info.darken-2 {
  background-color: #001d6c !important;
  border-color: #001d6c !important;
}
.info--text.text--darken-2 {
  color: #001d6c !important;
  caret-color: #001d6c !important;
}
.info.darken-3 {
  background-color: #000454 !important;
  border-color: #000454 !important;
}
.info--text.text--darken-3 {
  color: #000454 !important;
  caret-color: #000454 !important;
}
.info.darken-4 {
  background-color: #00003d !important;
  border-color: #00003d !important;
}
.info--text.text--darken-4 {
  color: #00003d !important;
  caret-color: #00003d !important;
}
.success {
  background-color: #2e7d32 !important;
  border-color: #2e7d32 !important;
}
.success--text {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}
.success.lighten-5 {
  background-color: #b8ffb2 !important;
  border-color: #b8ffb2 !important;
}
.success--text.text--lighten-5 {
  color: #b8ffb2 !important;
  caret-color: #b8ffb2 !important;
}
.success.lighten-4 {
  background-color: #9ceb97 !important;
  border-color: #9ceb97 !important;
}
.success--text.text--lighten-4 {
  color: #9ceb97 !important;
  caret-color: #9ceb97 !important;
}
.success.lighten-3 {
  background-color: #80ce7c !important;
  border-color: #80ce7c !important;
}
.success--text.text--lighten-3 {
  color: #80ce7c !important;
  caret-color: #80ce7c !important;
}
.success.lighten-2 {
  background-color: #65b263 !important;
  border-color: #65b263 !important;
}
.success--text.text--lighten-2 {
  color: #65b263 !important;
  caret-color: #65b263 !important;
}
.success.lighten-1 {
  background-color: #4a974a !important;
  border-color: #4a974a !important;
}
.success--text.text--lighten-1 {
  color: #4a974a !important;
  caret-color: #4a974a !important;
}
.success.darken-1 {
  background-color: #0a631a !important;
  border-color: #0a631a !important;
}
.success--text.text--darken-1 {
  color: #0a631a !important;
  caret-color: #0a631a !important;
}
.success.darken-2 {
  background-color: #004b01 !important;
  border-color: #004b01 !important;
}
.success--text.text--darken-2 {
  color: #004b01 !important;
  caret-color: #004b01 !important;
}
.success.darken-3 {
  background-color: #003300 !important;
  border-color: #003300 !important;
}
.success--text.text--darken-3 {
  color: #003300 !important;
  caret-color: #003300 !important;
}
.success.darken-4 {
  background-color: #002100 !important;
  border-color: #002100 !important;
}
.success--text.text--darken-4 {
  color: #002100 !important;
  caret-color: #002100 !important;
}
.warning {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}
.warning--text {
  color: #ffb300 !important;
  caret-color: #ffb300 !important;
}
.warning.lighten-5 {
  background-color: #ffffa8 !important;
  border-color: #ffffa8 !important;
}
.warning--text.text--lighten-5 {
  color: #ffffa8 !important;
  caret-color: #ffffa8 !important;
}
.warning.lighten-4 {
  background-color: #ffff8c !important;
  border-color: #ffff8c !important;
}
.warning--text.text--lighten-4 {
  color: #ffff8c !important;
  caret-color: #ffff8c !important;
}
.warning.lighten-3 {
  background-color: #ffff6f !important;
  border-color: #ffff6f !important;
}
.warning--text.text--lighten-3 {
  color: #ffff6f !important;
  caret-color: #ffff6f !important;
}
.warning.lighten-2 {
  background-color: #ffeb52 !important;
  border-color: #ffeb52 !important;
}
.warning--text.text--lighten-2 {
  color: #ffeb52 !important;
  caret-color: #ffeb52 !important;
}
.warning.lighten-1 {
  background-color: #ffce33 !important;
  border-color: #ffce33 !important;
}
.warning--text.text--lighten-1 {
  color: #ffce33 !important;
  caret-color: #ffce33 !important;
}
.warning.darken-1 {
  background-color: #df9800 !important;
  border-color: #df9800 !important;
}
.warning--text.text--darken-1 {
  color: #df9800 !important;
  caret-color: #df9800 !important;
}
.warning.darken-2 {
  background-color: #c07f00 !important;
  border-color: #c07f00 !important;
}
.warning--text.text--darken-2 {
  color: #c07f00 !important;
  caret-color: #c07f00 !important;
}
.warning.darken-3 {
  background-color: #a26600 !important;
  border-color: #a26600 !important;
}
.warning--text.text--darken-3 {
  color: #a26600 !important;
  caret-color: #a26600 !important;
}
.warning.darken-4 {
  background-color: #854e00 !important;
  border-color: #854e00 !important;
}
.warning--text.text--darken-4 {
  color: #854e00 !important;
  caret-color: #854e00 !important;
}